import React, { useState, useMemo } from "react";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";

import { Input } from "~/components/Base/BaseInputs";
import { BaseForm } from "~/components/Base/BaseForm";
import { Button, Link } from "~/components/Base/BaseActions";

import { INavigateFn } from "@utils/navigate";

const useStyles = makeStyles((theme) => ({
    notify: {
        ...theme.typography.body1,
        backgroundColor: darken(theme.palette.background.paper, .1),
        color: theme.palette.primary.contrastText,
        padding: `${theme.shape.authFormGutter}px 0`,
        textAlign: "center",
        width: "100%",
        boxShadow: "none",
    },
}));

interface ISignUpFormProps {
    navigate: INavigateFn;
}

interface ISignUpState {
    firstName: string;
    lastName: string;
    dealerTitle: string;
    dealership: string;
    dealerClass: string;
    email: string;
    phone: string;
}

type SignUpStateHandlerKeys = keyof ISignUpState;
type SignUpStateHandlers = Record<SignUpStateHandlerKeys, (val: string) => void>;

export const SignUpForm: React.FC<ISignUpFormProps> = ({
    navigate,
}) => {
    const [inputValues, updateValue] = useState<ISignUpState>({
        firstName: "",
        lastName: "",
        dealerTitle: "",
        dealership: "",
        dealerClass: "",
        email: "",
        phone: "",
    });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const inputHandler = useMemo<SignUpStateHandlers>(() => {
        const scopedHandler = (key: SignUpStateHandlerKeys) => (val: string) => {
            updateValue((state) => ({
                ...state,
                [key]: val,
            }));
        };
        return Object.keys(inputValues).reduce((acc, key) => ({
            ...acc,
            [key as SignUpStateHandlerKeys]: scopedHandler(key as SignUpStateHandlerKeys),
        }), {} as SignUpStateHandlers);
    }, [updateValue, inputValues]);

    const handleSubmit = () => {
        setLoading(true);
    };

    return (
        <BaseForm title="Sign Up" error={error}>
            <Input
                id={"input-first-name"}
                update={inputHandler.firstName}
                value={inputValues.firstName}
                label={"First Name"}
                placeholder={"First Name"}
                autoFocus
                grid={{
                    xs: 6,
                }}
            />
            <Input
                id={"input-last-name"}
                update={inputHandler.lastName}
                value={inputValues.lastName}
                label={"Last Name"}
                placeholder={"Last Name"}
                grid={{
                    xs: 6,
                }}
            />
            <Input
                id={"input-dealer-title"}
                update={inputHandler.dealerTitle}
                value={inputValues.dealerTitle}
                label={"Title of Dealership"}
                placeholder={"Title of Dealership"}
            />
            <Input
                id={"input-dealership"}
                update={inputHandler.dealership}
                value={inputValues.dealership}
                label={"Dealership"}
                placeholder={"Dealership"}
            />
            <Input
                id={"input-dealer-class"}
                update={inputHandler.dealerClass}
                value={inputValues.dealerClass}
                label={"Class of Dealer"}
                placeholder={"Class of Dealer"}
            />
            <Input
                id={"input-email"}
                update={inputHandler.email}
                value={inputValues.email}
                label={"Email"}
                placeholder={"Email"}
            />
            <Input
                id={"input-phone"}
                update={inputHandler.phone}
                value={inputValues.phone}
                label={"Phone"}
                placeholder={"Phone"}
            />
            <Button
                submit
                onClick={handleSubmit}
                label="Sign Up"
                loading={loading}
            />
            <Link to="/login">Go to Login</Link>
        </BaseForm>
    );
};